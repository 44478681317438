import React from 'react'
import styled from 'styled-components'
import { Form, Formik, FastField as Field } from 'formik'
import * as Yup from 'yup'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import useFirebase from 'hooks/useFirebase'

import { TextField } from 'formik-material-ui'

const Container = styled.div`
  margin-top: 64px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function FirebaseLogin() {
  const { firebase } = useFirebase()
  return (
    <Container>
      {firebase ? (
        <Formik
          initialValues={{ email: '', identifier: '', password: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)
            const { identifier, email, password } = values
            try {
              await firebase
                .auth()
                .signInWithEmailAndPassword(
                  `${identifier}___${email}`,
                  password
                )
            } finally {
              setSubmitting(true)
            }
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .required('Required'),
            password: Yup.string().required('Required'),
          })}
        >
          <Form>
            <Field
              component={TextField}
              margin="normal"
              required
              fullWidth
              id="identifier"
              label="Identifier"
              name="identifier"
              autoComplete="identifier"
              autoFocus
            />
            <Field
              component={TextField}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <Field
              component={TextField}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
              Sign In
            </Button>
          </Form>
        </Formik>
      ) : (
        <Spinner />
      )}
    </Container>
  )
}
